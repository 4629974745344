import React from "react";
// import ProductPage from "../ProductsPage/ProductPage.jsx";
import ProductPage from "../../components/Products/ProductPage";

import brands from "../../assets/img/products/mechanical/brands.png"

import p1 from "assets/img/products/mechanical/1.png";
import p2 from "assets/img/products/mechanical/2.jpg";
import p3 from "assets/img/products/mechanical/3.jpg";
import p4 from "assets/img/products/mechanical/4.jpg";
import p5 from "assets/img/products/mechanical/5.jpg";
import p6 from "assets/img/products/mechanical/6.jpg";
import p7 from "assets/img/products/mechanical/7.png";
// import p8 from "assets/img/products/mechanical/8.jpg";
// import p9 from "assets/img/products/mechanical/9.jpg";

import s1 from "assets/img/products/mechanical/slider/1.jpg";
import s2 from "assets/img/products/mechanical/slider/2.jpg";
import s3 from "assets/img/products/mechanical/slider/3.jpeg";
import s4 from "assets/img/products/mechanical/slider/4.jpg";

export default () => (
  <ProductPage prod="Mechanical" url="mechanical" brandsImg={brands} products={products} sliders={sliders} />
);

const sliders = [
    { img: s1},
    { img: s2},
    { img: s3},
    { img: s4}
]

const products = [
    { name: 'Pressure Gauges',      img: p1 },
    { name: 'Shaft',          img: p2 },
    { name: 'Couplings',            img: p3 },
    { name: 'Bearings',           img: p4 },
    { name: 'Fastners',           img: p5 },
    { name: 'Belts',             img: p6 },
    { name: 'Spare Parts',img: p7 }
]
