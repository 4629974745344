import React from "react";
// react component for creating beautiful carousel
import Carousel from "react-slick";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import { navigate } from 'gatsby'

// core components
import Header from "components/Header/Header.jsx";
import Footer from "components/Footer/Footer.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import HeaderLinks from "components/Header/HeaderLinks.jsx";
import Card from "components/Card/Card.jsx";
import carouselStyle from "assets/jss/material-kit-react/views/componentsSections/carouselStyle.jsx";

import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import landingPageStyle from "assets/jss/material-kit-react/views/landingPage.jsx";
import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";

// import quotebgbg from "assets/img/qt-bg-bg.png";
import { CssBaseline, Hidden, Typography } from "@material-ui/core";

// import NewsLetterSection from "../LandingPage/Sections/NewsLetterSection";
// import FooterSection from "../LandingPage/Sections/FooterSection";
// import brandsImg from "assets/img/our-brands.png";
// import ProductCarousel from "./ProductCarousel";
// import CategoriesSection from "./CategoriesSection";
import FooterSection from "../../pages/LandingPage/Sections/FooterSection";
import NewsLetterSection from "../../pages/LandingPage/Sections/NewsLetterSection";

const dashboardRoutes = [];

class ProductPage extends React.Component {
    render() {
        const { classes, prod, brandsImg, products, sliders, tools, url, ...rest } = this.props;
        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true
          };
        return (
            <div>
                <Header
                    color="transparent"
                    routes={dashboardRoutes}
                    brand="AL MIDIAFF INTERNATIONAL SUPPLIES"
                    // brand="AL MIDIAFF INTERNATIONAL Co. LLC"
                    // brand="AL MIDIAFF"
                    rightLinks={<HeaderLinks />}
                    fixed
                    changeColorOnScroll={{
                        height: 400,
                        color: "white"
                    }}
                    {...rest}
                />
                <CssBaseline />
                <div className={classNames(classes.main, classes.mainRaised)}>
                    <div className={classes.section} style={{ padding: "0 15%" }}>
                        <div style={{ height: 160 }}></div>

                        <div className={classes.container}>
                            <GridContainer aria-label="breadcrumb">
                                <a href="/">Home /</a>
                                &nbsp;
                                <a href="/products">Products /</a>
                                &nbsp;
                                <a href="#">{prod}</a>
                            </GridContainer>
                        </div>
                        <div style={{ height: 30 }}></div>
                        <div className={classes.container}>
                            <FormControl className={classes.formControl} style={{ minWidth: "160px" }}>
                                <h3><InputLabel id="product-select-label"><h3>Our Products</h3></InputLabel></h3>
                                <Select
                                    style={{minHeight:"60px"}}
                                    labelId="product-select-label"
                                    id="product-select"
                                    // open={open}
                                    // onClose={handleClose}
                                    // onOpen={handleOpen}
                                    // value={url}
                                    onChange={event => {
                                        if(event.target.value) navigate('/product/' + event.target.value + '/');
                                        //document.getElementById('product-select').value = event.target.value;
                                    }}
                                >
                                    <MenuItem value="">
                                        <em><h3>Our Products</h3></em>
                                    </MenuItem>
                                    <MenuItem value="power-tools"><h3>Power Tools</h3></MenuItem>
                                    <MenuItem value="hand-tools"><h3>Hand Tools</h3></MenuItem>
                                    <MenuItem value="instrumentation"><h3>Instrumentation</h3></MenuItem>
                                    <MenuItem value="electrical"><h3>Electrical</h3></MenuItem>
                                    <MenuItem value="mechanical"><h3>Mechanical</h3></MenuItem>
                                    <MenuItem value="welding"><h3>Welding</h3></MenuItem>
                                    <MenuItem value="stainless-steel"><h3>Stainless Steel</h3></MenuItem>
                                    <MenuItem value="ppe-safety"><h3>PPE & Safety</h3></MenuItem>
                                    <MenuItem value="furniture"><h3>Furniture</h3></MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ height: 40 }}></div>
                        <div style={{ background: "#fff", maxWidth: "100%" }}>
                            <div className={classes.section} style={{}}>
                                <GridContainer>
                                    <GridItem xs={12} sm={12} md={4} style={{}}>
                                        <h1 className={classes.title} style={{ color: "#6C648B" }}>{prod}</h1>
                                        <div style={{ height: 40 }}></div>
                                        <img src={brandsImg} alt={prod} style={{ width: "100%" }} />
                                    </GridItem>
                                    <Hidden xsDown>
                                    <GridItem xs={12} sm={12} md={8} style={{}}>
                                        <GridContainer>
                                            <GridItem xs={12} sm={12} md={12} className={classes.marginAuto}>
                                                <Card carousel>
                                                    <Carousel {...settings}>
                                                        {sliders.map((slider) =>
                                                            <div>
                                                                <img
                                                                    src={slider.img}
                                                                    alt=" "
                                                                    className="slick-image"
                                                                />
                                                            </div>
                                                        )}
                                                    </Carousel>
                                                </Card>
                                            </GridItem>
                                        </GridContainer>
                                    </GridItem>
                                    </Hidden>
                                </GridContainer>
                            </div>
                            <Hidden xsDown>
                                <div style={{ height: 80 }}></div>
                            </Hidden>
                            <div className={classes.container}>
                                <GridContainer>
                                    {products && products.map((product) =>

                                        <GridItem xs={6} sm={3} md={2} style={{ padding: "15px", textAlign:"center", maxHeight: "300px" }}>
                                            {/* <img src={product.img} style={{ maxWidth: "160px", height:"160px" }} /> */}
                                            <img src={product.img} style={{ maxWidth: "100%", maxHeight: "80%" }} />
                                            <h4 style={{ color: "black", textAlign: "center", position:"absolute", bottom: "0", width: "100%" }}>{product.name}</h4>
                                        </GridItem>

                                    )}
                                    {tools && <GridItem xs={12} sm={12} md={12} style={{ padding: "15px" }}>
                                        <img src={tools} style={{ width: "100%" }} />
                                    </GridItem>}
                                </GridContainer>
                            </div>

                            <br />
                            <br />
                            <div className={classes.section} style={{}}>

                            </div>
                        </div>
                    </div>
                    <div style={{ background: "#6C648B", width: "100%" }}>
                        <div className={classes.container} >
                            <NewsLetterSection />
                        </div>
                    </div>

                    <div style={{ background: "#B6A19E", minHeight: "2em" }}></div>
                    <FooterSection />

                </div>
                <Footer />
            </div>
        );
    }
}

export default withStyles(landingPageStyle, productStyle, carouselStyle)(ProductPage);
